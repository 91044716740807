<template>
  <img
    ref="imageRef"
    class="transform-gpu will-change-auto"
    :class="[
      {
        ' transition-opacity duration-400 ease-sine-out': effect === 'fade'
      },
      effect === 'fade' && isVisible ? 'opacity-100' : 'opacity-0'
    ]"
    :loading="loading"
    :src="src"
    :width="width"
    :height="height"
    :alt="alt"
    @load="onLoad"
  />
</template>

<script setup lang="ts">
import { UseIntersectionObserverOptions, useIntersectionObserver } from '@vueuse/core'

interface Props {
  loading?: 'lazy' | 'eager'
  src: string
  width: number
  height: number
  preload?: boolean
  alt?: string
  type?: string
  effect?: 'fade' | 'none'
  observerOptions?: UseIntersectionObserverOptions
}

const props = withDefaults(defineProps<Props>(), {
  alt: '',
  loading: 'lazy',
  effect: 'fade',
  observerOptions: () => ({
    threshold: 0.5
  })
})

const imageRef = ref<HTMLElement | null>(null)
const isLoaded = ref(false)
const isVisible = ref(false)

function onLoad() {
  isLoaded.value = true
}

if (props.preload) {
  useHead({
    link: [
      { rel: 'preload', fetchpriority: 'high', as: 'image', href: props.src, type: props.type }
    ]
  })
}

const { stop } = useIntersectionObserver(
  imageRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      isVisible.value = true
    }
  },
  props.observerOptions
)

onUnmounted(() => {
  stop()
})
</script>
